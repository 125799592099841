import React from 'react';
import styles from './NotificationBar.module.css';

const NotificationBar = ({ notifications }) => {
  return (
    <div className={styles.notificationBarWrapper}>
      <div className={styles.notificationBar}>
        {notifications.map((notification, index) => (
          <div key={index} className={styles.notification}>
            <span className={styles.icon}>{notification.icon}</span>
            <span className={styles.text}>{notification.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationBar;