import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import { CartContext } from '../../contexts/CartContext';
import styles from './Bag.module.css';
import { getImageUrl } from '../../utils/imageUtils';

function Bag() {
  const { cart, removeFromCart, updateQuantity, clearCart } = useContext(CartContext);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [error, setError] = useState('');
  const [isCheckout, setIsCheckout] = useState(false);
  const navigate = useNavigate();

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const calculateTotal = () => {
      if (cart.items && Array.isArray(cart.items)) {
        const total = cart.items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
        setTotalPrice(total);
      }
    };
    calculateTotal();
  }, [cart]);

  const handleQuantityChange = async (itemId, newQuantity) => {
    if (newQuantity > 0) {
      try {
        await updateQuantity(itemId, newQuantity);
      } catch (err) {
        console.error('수량 업데이트에 실패했습니다:', err);
        setError('수량 업데이트에 실패했습니다. 다시 시도해 주세요.');
      }
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      await removeFromCart(itemId);
    } catch (err) {
      console.error('아이템 제거에 실패했습니다:', err);
      setError('아이템 제거에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  const handleCheckout = () => {
    setIsCheckout(true);
  };

  const handleSubmitOrder = async () => {
    if (adminPassword !== 'rwanda@138') {
      setError('잘못된 관리자 비밀번호입니다.');
      return;
    }

    try {
      const orderData = {
        customerName: name,
        customerAddress: address,
        customerPhone: phone,
        items: cart.items,
        totalPrice: totalPrice
      };

      const response = await api.createOrder(orderData);
      console.log('주문 완료:', response.data);

      await clearCart();

      setName('');
      setAddress('');
      setPhone('');
      setAdminPassword('');
      setError('');
      setIsCheckout(false);

      navigate('/');
    } catch (err) {
      console.error('주문 처리 중 오류 발생:', err);
      setError('주문 처리 중 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <div className={styles.bag}>
      <h1>장바구니</h1>
      {!cart.items || cart.items.length === 0 ? (
        <p>장바구니가 비어 있습니다.</p>
      ) : (
        <div className={styles.bagContent}>
          <div className={styles.bagItems}>
            {cart.items.map((item, index) => (
              <div key={index} className={styles.bagItem}>
                <img src={getImageUrl(item.image)} alt={item.name} className={styles.itemImage} />
                <div className={styles.itemDetails}>
                  <h2>{item.brand}</h2>
                  <p>{item.name}</p>
                  {item.type && <p>옵션: {item.type}</p>}
                  <p className={styles.itemPrice}>USD {item.price.toFixed(2)}</p>
                  <div className={styles.quantityControl}>
                    <button onClick={() => handleQuantityChange(item.id, item.quantity - 1)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleQuantityChange(item.id, item.quantity + 1)}>+</button>
                  </div>
                </div>
                <button 
                  className={styles.removeButton} 
                  onClick={() => handleRemoveItem(item.id)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          <div className={styles.orderSummary}>
            <h2>주문 요약</h2>
            <div className={styles.summaryItem}>
              <span>소계</span>
              <span>USD {totalPrice.toFixed(2)}</span>
            </div>
            <div className={styles.summaryItem}>
              <span>배송비</span>
              <span>무료</span>
            </div>
            <div className={styles.summaryTotal}>
              <span>예상 총액</span>
              <span>USD {totalPrice.toFixed(2)}</span>
            </div>
            {!isCheckout ? (
              <button className={styles.checkoutButton} onClick={handleCheckout}>결제 진행</button>
            ) : (
              <form onSubmit={(e) => { e.preventDefault(); handleSubmitOrder(); }} className={styles.checkoutForm}>
                <input
                  type="text"
                  placeholder="이름"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="주소"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
                <input
                  type="tel"
                  placeholder="전화번호"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="관리자 비밀번호"
                  value={adminPassword}
                  onChange={(e) => setAdminPassword(e.target.value)}
                  required
                />
                {error && <p className={styles.error}>{error}</p>}
                <button type="submit" className={styles.submitOrderButton}>주문 제출</button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Bag;