import React, { useState, useEffect } from "react";
import api from "../../utils/api";
import NotificationBar from "../../components/Layout/NotificationBar/NotificationBar";
import Slider from "../../components/Home/Slider/Slider";
import MidBanner from "../../components/Home/MidBanner/MidBanner";
import ProductList from "../../components/ProductList/ProductList";
import CategoryProductList from "../../components/CategoryProductList/CategoryProductList";
import styles from "./Home.module.css";

function Home() {
  const [products, setProducts] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [mdPicks, setMdPicks] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const productsRes = await api.getProducts();
        
        setProducts(productsRes.data);
        // 임시로 추천 상품들을 전체 상품 목록에서 필터링
        setBestSellers(productsRes.data.filter(p => p.tags.includes("BEST")).slice(0, 5));
        setMdPicks(productsRes.data.filter(p => p.isMdPick).slice(0, 5));
        setNewArrivals(productsRes.data.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)).slice(0, 5));
        
        setLoading(false);
      } catch (err) {
        console.error("API 호출 중 오류 발생:", err);
        setError(`데이터를 불러오는 데 실패했습니다: ${err.message}`);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const notifications = [
    { icon: "🚚", text: "USD 60 이상 구매시 무료 배송!" },
    { icon: "💰", text: "OLIVE YOUNG 앱에서 USD 20 할인!" },
    { icon: "🎉", text: "신규 회원 가입시 50% 할인 쿠폰 증정!" },
  ];

  const featuredTabs = [
    { id: "bestSellers", name: "베스트셀러", products: bestSellers },
    { id: "mdPicks", name: "MD's PICK", products: mdPicks },
    { id: "newProducts", name: "신상품", products: newArrivals },
  ];

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.homeContainer}>
      <NotificationBar notifications={notifications} />
      <div className={styles.sliderContainer}>
        <Slider />
        <MidBanner />
      </div>
      <div className={styles.featuredProductsContainer}>
        <h2 className={styles.sectionTitle}>추천 상품</h2>
        <ProductList showTabs={true} tabs={featuredTabs} />
      </div>
      <div className={styles.allProductsContainer}>
        <h2 className={styles.sectionTitle}>전체 상품</h2>
        <CategoryProductList products={products} />
      </div>
    </div>
  );
}

export default Home;