import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Header.module.css";
import TopBanner from "./TopBanner";
import { CartContext } from "../../../contexts/CartContext";
import { getPublicUrl } from "../../../utils/utils";

const Header = () => {
  const { getTotalItems, getTotalQuantity, cart } = useContext(CartContext);
  const [totalItems, setTotalItems] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    setTotalItems(getTotalItems());
    setTotalQuantity(getTotalQuantity());
  }, [cart, getTotalItems, getTotalQuantity]);

  return (
    <header className={styles.header}>
      <TopBanner />
      <div className={styles.headerContainer}>
        <div className={styles.mainHeader}>
          <Link to="/" className={styles.logoLink}>
            <img
              src={getPublicUrl("/images/logo/sample-logo.png")}
              alt="K-Mart Rwanda"
              className={styles.logo}
            />
          </Link>

          <div className={styles.userActions}>
            <Link to="/bag" className={styles.actionButton}>
              <div className={styles.bagIconWrapper}>
                <FontAwesomeIcon icon={faShoppingBag} className={styles.icon} />
                {totalItems > 0 && (
                  <span className={styles.bagItemCount}>{totalItems}</span>
                )}
              </div>
              <span>장바구니 ({totalQuantity})</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;