import React from 'react';
import styles from './ProductReviews.module.css';

function ProductReviews({ reviews }) {
  return (
    <div className={styles.productReviews}>
      <h3>Customer Reviews</h3>
      {reviews.map((review, index) => (
        <div key={index} className={styles.review}>
          <div className={styles.reviewHeader}>
            <span className={styles.rating}>{'★'.repeat(review.rating)}</span>
            <span className={styles.username}>{review.username}</span>
            <span className={styles.date}>{review.date}</span>
          </div>
          <p className={styles.reviewText}>{review.text}</p>
          {review.images && review.images.length > 0 && (
            <div className={styles.reviewImages}>
              {review.images.map((image, imgIndex) => (
                <img key={imgIndex} src={image} alt={`Review ${imgIndex + 1}`} />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProductReviews;