import React, { useState, useEffect, useRef } from 'react';
import styles from './Slider.module.css';

const slides = [
  {
    image: '/images/temp/main-banner/banner01.jpg',
    title: '신제품 출시',
    description: '화사한 봄을 위한 신제품 컬렉션을 만나보세요',
  },
  {
    image: '/images/temp/main-banner/banner02.jpg',
    title: '베스트셀러 세일',
    description: '인기 상품 최대 50% 할인 중',
  },
  {
    image: '/images/temp/main-banner/banner03.jpg',
    title: '여름 스킨케어 특집',
    description: '무더운 여름을 위한 완벽한 스킨케어 루틴',
  },
];

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, 7000);
    }
    return () => clearInterval(interval);
  }, [isPaused]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handlePlayPause = () => {
    setIsPaused(!isPaused);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentX = e.touches[0].clientX;
    const diff = startX - currentX;
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const currentX = e.clientX;
    const diff = startX - currentX;
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className={styles.sliderContainer}>
      <div 
        ref={sliderRef}
        className={styles.slider}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={styles.slide}
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            <div className={styles.slideContent}>
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.controls}>
        <button onClick={handlePrev} className={styles.controlButton}>&lt;</button>
        <span className={styles.pageInfo}>{currentIndex + 1} / {slides.length}</span>
        <button onClick={handleNext} className={styles.controlButton}>&gt;</button>
      </div>
      <button onClick={handlePlayPause} className={styles.playPauseButton}>
        {isPaused ? '▶' : '❚❚'}
      </button>
    </div>
  );
}

export default Slider;