import React from "react";
import styles from "./TopBanner.module.css";
import { getPublicUrl } from "../../../utils/utils";

const TopBanner = () => {
  return (
    <div className={styles.topBannerContainer}>
      <div
        className={styles.topBanner}
        style={{
          backgroundImage: `url(${getPublicUrl(
            "/images/temp/top-banner/top-banner.jpg"
          )})`,
        }}
      />
    </div>
  );
};

export default TopBanner;
