import React, { useState } from 'react';
import styles from './ProductImageGallery.module.css';

function ProductImageGallery({ images }) {
  const [mainImage, setMainImage] = useState(images[0]);

  return (
    <div className={styles.gallery}>
      <div className={styles.mainImage}>
        <img src={mainImage} alt="Product" />
      </div>
      <div className={styles.thumbnails}>
        {images.map((image, index) => (
          <img 
            key={index} 
            src={image} 
            alt={`Thumbnail ${index + 1}`} 
            onClick={() => setMainImage(image)}
            className={mainImage === image ? styles.active : ''}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductImageGallery;