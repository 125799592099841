import React, { useState } from 'react';
import styles from './ProductDetails.module.css';

function ProductDetails({ details }) {
  const [expanded, setExpanded] = useState(false);

  if (!details) {
    return <div>No product details available.</div>;
  }

  return (
    <div className={styles.productDetails}>
      {details.description && (
        <div dangerouslySetInnerHTML={{ __html: details.description }} />
      )}
      {details.featuredIngredients && (
        <>
          <h3>Featured ingredients</h3>
          <div dangerouslySetInnerHTML={{ __html: details.featuredIngredients }} />
        </>
      )}
      {details.howToUse && (
        <>
          <h3>How to use</h3>
          <div dangerouslySetInnerHTML={{ __html: details.howToUse }} />
        </>
      )}
      {details.detailPageImages && details.detailPageImages.length > 0 && !expanded && (
        <button className={styles.readMore} onClick={() => setExpanded(true)}>Read more</button>
      )}
      {expanded && details.detailPageImages && (
        <div className={styles.expandedContent}>
          {details.detailPageImages.map((image, index) => (
            <img key={index} src={image} alt={`Detailed view of the product - part ${index + 1}`} />
          ))}
        </div>
      )}
    </div>
  );
}

export default ProductDetails;