import React from "react";
import styles from "./MidBanner.module.css";
import { getPublicUrl } from "../../../utils/utils";

const MidBanner = () => {
  return (
    <div className={styles.midBannerContainer}>
      <div
        className={styles.midBanner}
        style={{
          backgroundImage: `url(${getPublicUrl(
            "/images/temp/mid-banner/mid-banner.jpg"
          )})`,
        }}
      />
    </div>
  );
};

export default MidBanner;
