import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProductInfo.module.css";
import { CartContext } from "../../contexts/CartContext";
import { getImageUrl } from "../../utils/imageUtils";

function ProductInfo({ product }) {
  const [selectedType, setSelectedType] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    if (product.types && product.types.length > 0) {
      setSelectedType(product.types[0]);
    } else {
      setSelectedType(null);
    }
  }, [product]);

  const handleAddToCart = useCallback(async () => {
    const newItem = {
      id: product.id,
      name: product.name,
      brand: product.brand,
      price: selectedType
        ? selectedType.discountPrice || selectedType.price
        : product.basePrice,
      quantity: quantity,
      type: selectedType ? selectedType.name : null,
      image: product.mainImages[0],
    };

    try {
      const success = await addToCart(newItem);
      if (success) {
        setShowPopup(true);
        setPopupMessage("상품이 장바구니에 추가되었습니다!");
      } else {
        throw new Error("Failed to add item to cart");
      }
    } catch (err) {
      console.error("장바구니에 추가하는데 실패했습니다:", err);
      setPopupMessage(
        "장바구니에 추가하는데 실패했습니다. 다시 시도해 주세요."
      );
      setShowPopup(true);
    }
  }, [product, selectedType, quantity, addToCart]);

  const handleGoToBag = useCallback(() => {
    setShowPopup(false);
    navigate("/bag");
  }, [navigate]);

  const handleStayOnPage = useCallback(() => {
    setShowPopup(false);
  }, []);

  if (!product) {
    return <div>상품 정보를 불러올 수 없습니다.</div>;
  }

  const currentPrice = selectedType
    ? selectedType.discountPrice || selectedType.price
    : product.basePrice;

  return (
    <div className={styles.productInfo}>
      {product.tags &&
        product.tags.map((tag, index) => (
          <span key={index} className={styles.tag}>
            {tag}
          </span>
        ))}
      <h1 className={styles.brand}>{product.brand}</h1>
      <h2 className={styles.name}>{product.name}</h2>
      <div className={styles.rating}>
        {product.rating} ★ ({product.reviewCount} 리뷰)
      </div>
      <div className={styles.price}>
        <span>USD {currentPrice.toFixed(2)}</span>
      </div>
      {product.types && product.types.length > 0 && (
        <div className={styles.typeSelection}>
          {product.types.map((type, index) => (
            <div
              key={index}
              className={`${styles.typeOption} ${
                selectedType && selectedType.name === type.name
                  ? styles.selected
                  : ""
              }`}
              onClick={() => setSelectedType(type)}
            >
              {type.image && (
                <img
                  src={getImageUrl(type.image)}
                  alt={type.name}
                  className={styles.typeImage}
                />
              )}
              <div className={styles.typeInfo}>
                <span className={styles.typeName}>{type.name}</span>
                <span className={styles.typePrice}>
                  USD {type.price.toFixed(2)}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.quantity}>
        <button onClick={() => setQuantity((q) => Math.max(1, q - 1))}>
          -
        </button>
        <input
          type="number"
          value={quantity}
          onChange={(e) =>
            setQuantity(Math.max(1, parseInt(e.target.value) || 1))
          }
        />
        <button onClick={() => setQuantity((q) => q + 1)}>+</button>
      </div>
      <button className={styles.addToCart} onClick={handleAddToCart}>
        장바구니에 추가 - USD {(currentPrice * quantity).toFixed(2)}
      </button>

      {showPopup && (
        <div className={styles.popup}>
          <p>{popupMessage}</p>
          <button onClick={handleGoToBag}>장바구니로 이동</button>
          <button onClick={handleStayOnPage}>계속 쇼핑하기</button>
        </div>
      )}
    </div>
  );
}

export default ProductInfo;