import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./ProductList.module.css";

function ProductList({ showTabs, tabs }) {
  const [activeTab, setActiveTab] = useState(tabs ? tabs[0]?.id : null);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const itemsPerPage = 5;

  useEffect(() => {
    if (showTabs && activeTab) {
      const activeTabData = tabs.find((tab) => tab.id === activeTab);
      if (activeTabData && activeTabData.products) {
        setDisplayedProducts(activeTabData.products);
      }
    }
    setCurrentPage(0);
  }, [activeTab, showTabs, tabs]);

  const totalPages = Math.ceil(displayedProducts.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const currentProducts = displayedProducts.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className={styles.productListContainer}>
      {showTabs && tabs && (
        <div className={styles.tabs}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`${styles.tabButton} ${
                activeTab === tab.id ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </button>
          ))}
        </div>
      )}
      <div className={styles.productGrid}>
        {currentProducts.map((product) => {
          const types = Array.isArray(product.types) ? product.types : JSON.parse(product.types);
          const firstType = types.length > 0 ? types[0] : null;
          const price = firstType && firstType.price ? firstType.price : null;
          const discountPrice = firstType && firstType.discountPrice ? firstType.discountPrice : null;

          return (
            <div key={product.id} className={styles.productItem}>
              <Link to={`/product/${product.id}`}>
                <div className={styles.productImage}>
                  <img
                    src={product.mainImages[0]}
                    alt={product.name}
                    className={styles.image}
                  />
                  {product.tags && product.tags.length > 0 && (
                    <span className={styles.productTag}>{product.tags[0]}</span>
                  )}
                </div>
                <div className={styles.productInfo}>
                  <p className={styles.brand}>{product.brand}</p>
                  <h3 className={styles.productName}>{product.name}</h3>
                  <div className={styles.rating}>
                    {typeof product.rating === "number" ? (
                      <>
                        {"★".repeat(Math.floor(product.rating))}
                        {"☆".repeat(5 - Math.floor(product.rating))}
                        <span className={styles.ratingValue}>
                          {product.rating.toFixed(1)}
                        </span>
                      </>
                    ) : (
                      <span>평가 없음</span>
                    )}
                  </div>
                  <p className={styles.price}>
                    {price !== null ? (
                      <>
                        {discountPrice && (
                          <span className={styles.discountPrice}>
                            USD {price.toFixed(2)}
                          </span>
                        )}
                        USD {(discountPrice || price).toFixed(2)}
                      </>
                    ) : (
                      <span>가격 정보 없음</span>
                    )}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className={styles.navigation}>
        <button className={styles.navButton} onClick={handlePrevPage}>
          &lt; 이전
        </button>
        <button className={styles.navButton} onClick={handleNextPage}>
          다음 &gt;
        </button>
      </div>
    </div>
  );
}

export default ProductList;