import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import api from "../utils/api";
import { AuthContext } from "./AuthContext";

export const CartContext = createContext(null);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({ items: [] });
  const { isAuthenticated } = useContext(AuthContext);

  const fetchCart = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const response = await api.getCart();
      setCart(response.data);
    } catch (error) {
      console.error("Failed to fetch cart:", error);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCart();
    }
  }, [isAuthenticated, fetchCart]);

  const addToCart = useCallback(async (item) => {
    try {
      const response = await api.addToCart({
        productId: item.id,
        quantity: item.quantity
      });
      setCart(response.data);
      return true;
    } catch (error) {
      console.error("Failed to add item to cart:", error);
      return false;
    }
  }, []);

  const removeFromCart = useCallback(async (itemId) => {
    try {
      const response = await api.removeFromCart(itemId);
      setCart(response.data);
    } catch (error) {
      console.error("Failed to remove item from cart:", error);
    }
  }, []);

  const updateQuantity = useCallback(async (itemId, quantity) => {
    try {
      const response = await api.updateCartQuantity(itemId, quantity);
      setCart(response.data);
    } catch (error) {
      console.error("Failed to update quantity:", error);
    }
  }, []);

  const clearCart = useCallback(async () => {
    try {
      await api.clearCart();
      setCart({ items: [] });
    } catch (error) {
      console.error("Failed to clear cart:", error);
    }
  }, []);

  const getTotalItems = useCallback(() => {
    return cart.items ? cart.items.length : 0;
  }, [cart]);

  const getTotalQuantity = useCallback(() => {
    if (!cart.items || !Array.isArray(cart.items)) {
      return 0;
    }
    return cart.items.reduce((total, item) => total + (item.quantity || 0), 0);
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,
        getTotalItems,
        getTotalQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};