import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Nunito', sans-serif;
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    margin-bottom: ${theme.spacing.medium};
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  button {
    cursor: pointer;
    background-color: ${theme.colors.primary};
    color: white;
    border: none;
    padding: ${theme.spacing.small} ${theme.spacing.medium};
    border-radius: 4px;
    font-size: 1rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
  }

  /* 추가적인 폰트 웨이트 사용 예시 */
  .light {
    font-weight: 300;
  }

  .regular {
    font-weight: 400;
  }

  .semibold {
    font-weight: 600;
  }

  .bold {
    font-weight: 700;
  }
`;