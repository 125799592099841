import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./CategoryProductList.module.css";

const MAX_ITEMS = 100;

function CategoryProductList({ products }) {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [displayedProducts, setDisplayedProducts] = useState([]);

  const categories = [
    "All",
    "Skincare",
    "Makeup",
    "Bath & Body",
    "Hair",
    "Masks",
    "Sun Care",
    "K-POP",
    "Tools & Brushes",
    "Wellness",
    "Supplements",
  ];

  useEffect(() => {
    const filteredProducts =
      selectedCategory === "All"
        ? products
        : products.filter((product) => product.category === selectedCategory);

    const sortedProducts = filteredProducts
      .sort((a, b) => b.reviewCount - a.reviewCount)
      .slice(0, MAX_ITEMS);

    setDisplayedProducts(sortedProducts);
  }, [selectedCategory, products]);

  return (
    <div className={styles.categoryProductListContainer}>
      <div className={styles.categories}>
        {categories.map((category) => (
          <button
            key={category}
            className={`${styles.categoryButton} ${
              selectedCategory === category ? styles.active : ""
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className={styles.productGrid}>
        {displayedProducts.map((product) => {
          const types = Array.isArray(product.types) ? product.types : JSON.parse(product.types);
          const firstType = types.length > 0 ? types[0] : null;
          const price = firstType && firstType.price ? firstType.price : null;
          const discountPrice = firstType && firstType.discountPrice ? firstType.discountPrice : null;

          return (
            <div key={product.id} className={styles.productItem}>
              <Link to={`/product/${product.id}`}>
                <div className={styles.productImage}>
                  <img
                    src={product.mainImages[0]}
                    alt={product.name}
                    className={styles.image}
                  />
                  {product.tags && product.tags.length > 0 && (
                    <span className={styles.productTag}>{product.tags[0]}</span>
                  )}
                </div>
                <div className={styles.productInfo}>
                  <p className={styles.brand}>{product.brand}</p>
                  <h3 className={styles.productName}>{product.name}</h3>
                  <div className={styles.rating}>
                    {typeof product.rating === "number" ? (
                      <>
                        {"★".repeat(Math.floor(product.rating))}
                        {"☆".repeat(5 - Math.floor(product.rating))}
                        <span className={styles.ratingValue}>
                          {product.rating.toFixed(1)}
                        </span>
                      </>
                    ) : (
                      <span>평가 없음</span>
                    )}
                  </div>
                  <p className={styles.price}>
                    {price !== null ? (
                      <>
                        {discountPrice && (
                          <span className={styles.discountPrice}>
                            USD {price.toFixed(2)}
                          </span>
                        )}
                        USD {(discountPrice || price).toFixed(2)}
                      </>
                    ) : (
                      <span>가격 정보 없음</span>
                    )}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CategoryProductList;