import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../utils/api';
import styles from './ProductPage.module.css';
import ProductImageGallery from '../../components/ProductImageGallery/ProductImageGallery';
import ProductInfo from '../../components/ProductInfo/ProductInfo';
import ProductDetails from '../../components/ProductDetails/ProductDetails';
import ProductReviews from '../../components/ProductReviews/ProductReviews';

function ProductPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [activeTab, setActiveTab] = useState('details');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const response = await api.getProductById(id);
        const productData = response.data;

        // Parse JSON strings if necessary
        productData.details = typeof productData.details === 'string' ? JSON.parse(productData.details) : productData.details;
        productData.mainImages = typeof productData.mainImages === 'string' ? JSON.parse(productData.mainImages) : productData.mainImages;
        productData.types = typeof productData.types === 'string' ? JSON.parse(productData.types) : productData.types;

        setProduct(productData);
        setReviews(productData.reviews || []);
        setLoading(false);
      } catch (err) {
        console.error('상품 정보를 불러오는 데 실패했습니다:', err);
        setError('상품 정보를 불러오는 데 실패했습니다.');
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);
  
  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;
  if (!product) return <div>상품 정보를 찾을 수 없습니다.</div>;

  return (
    <div className={styles.productPage}>
      <div className={styles.breadcrumbs}>
        {product.category}
      </div>
      <div className={styles.productMain}>
        <ProductImageGallery images={product.mainImages} />
        <ProductInfo product={product} />
      </div>
      <div className={styles.productTabs}>
        <button 
          className={`${styles.tabButton} ${activeTab === 'details' ? styles.active : ''}`}
          onClick={() => setActiveTab('details')}
        >
          상품 상세 정보
        </button>
        <button 
          className={`${styles.tabButton} ${activeTab === 'reviews' ? styles.active : ''}`}
          onClick={() => setActiveTab('reviews')}
        >
          리뷰 ({product.reviewCount})
        </button>
      </div>
      {activeTab === 'details' ? (
        <ProductDetails details={product.details} />
      ) : (
        <ProductReviews reviews={reviews} />
      )}
    </div>
  );
}

export default ProductPage;