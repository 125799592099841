import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.k-martrwanda.com/api',
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && !error.config._retry) {
      error.config._retry = true;
      try {
        // 리프레시 토큰 로직 (필요한 경우)
        return api(error.config);
      } catch (refreshError) {
        localStorage.removeItem('token');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const apiMethods = {
  login: (credentials) => api.post('/auth/login', credentials),
  logout: () => api.post('/auth/logout'),
  getProducts: () => api.get('/products'),
  getProductById: (id) => api.get(`/products/${id}`),
  getCart: () => api.get('/cart'),
  addToCart: (data) => api.post('/cart/add', data),
  removeFromCart: (itemId, itemType) => api.delete(`/cart/remove/${itemId}`, { data: { itemType } }),
  updateCartQuantity: (itemId, itemType, quantity) => api.put('/cart/update', { itemId, itemType, quantity }),
  clearCart: () => api.delete('/cart/clear'),
  createOrder: (orderData) => api.post('/orders', orderData),
  submitInquiry: (productId, inquiryData) => api.post(`/products/${productId}/inquiries`, inquiryData),
  verifyToken: () => api.get('/auth/verify'),
};

export default apiMethods;